<template>
  <div class="v-date-range">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      v-bind="menuProps"
    >
      <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-on="on"
        class="v-date-range__input-field"
        :value="inputValue"
        readonly
        :rules="rules"
        dense
        outlined
        :disabled="disabled"
        :placeholder="placeholder"
        v-bind="inputProps"
      ></v-text-field>
      </template>
      <v-card class="v-date-range__menu-content">
        <v-card-text>
          <div
            :data-days="highlightDates.length"
            :class="{
              'v-date-range__pickers': true,
              'v-date-range--highlighted': highlightDates.length
            }"
          >
            <v-card-title v-if="$slots.title">
              <slot name="title" v-if="$slots.title"></slot>
            </v-card-title>
            <v-card-text>
              <div class="v-date-range__content">
                <v-list v-if="!noPresets" class="mr-4">
                  <v-subheader>{{ presetLabel }}</v-subheader>
                  <v-list-tile
                    v-for="(preset, index) in presets"
                    v-model="isPresetActive[index]"
                    :key="index"
                    @click="selectPreset(index)"
                  >
                    <v-list-tile-content>
                      {{ preset.label }}
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-date-picker
                  class="mr-4 v-date-range__picker--start v-date-range__picker"
                  v-model="pickerStart"
                  :locale="locale"
                  :first-day-of-week="firstDayOfWeek"
                  :min="min"
                  :max="pickerEnd || max"
                  :no-title="noTitle"
                  :next-icon="nextIcon"
                  :prev-icon="prevIcon"
                  :events="highlightDates"
                  :event-color="highlightClasses"
                ></v-date-picker>
                <v-date-picker
                  class="v-date-range__picker--end v-date-range__picker"
                  v-model="pickerEnd"
                  :locale="locale"
                  :first-day-of-week="firstDayOfWeek"
                  :min="pickerStart || min"
                  :max="max"
                  :no-title="noTitle"
                  :next-icon="nextIcon"
                  :prev-icon="prevIcon"
                  :events="highlightDates"
                  :event-color="highlightClasses"
                ></v-date-picker>
              </div>
            </v-card-text>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="reset">{{ mergedActionLabels.reset }}</v-btn>
          <v-btn text @click="menu = false">{{
              mergedActionLabels.cancel
            }}
          </v-btn>
          <v-btn
            @click="applyRange"
            color="primary"
          >{{ mergedActionLabels.apply }}
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { format, parse, differenceInCalendarDays, addDays } from 'date-fns'
import { isEmpty, toDateSting } from '@/helpers'

export default {
  name: 'DateRange',
  props: {
    value: {
      type: Object,
      default: () => {
        return { start: null, end: null }
      },
    },
    rules:{
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    presets: {
      type: Array,
      default: () => {
        return []
      },
    },
    noPresets: {
      type: Boolean,
      default: false,
    },
    startLabel: {
      type: String,
      default: 'С',
    },
    endLabel: {
      type: String,
      default: 'По',
    },
    separatorLabel: {
      type: String,
      default: 'По',
    },
    presetLabel: {
      type: String,
      default: '',
    },
    actionLabels: {
      type: Object,
      default: () => {
        return {
          apply: 'Применить',
          cancel: 'Отмена',
          reset: 'Обнулить',
        }
      },
    },

    min: {
      type: String,
      default: undefined,
    },

    max: {
      type: String,
      default: undefined,
    },

    locale: {
      type: String,
      default: 'en-us',
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    highlightColor: {
      type: String,
      default: 'blue lighten-5',
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    nextIcon: {
      type: String,
      default: '$vuetify.icons.next',
    },
    prevIcon: {
      type: String,
      default: '$vuetify.icons.prev',
    },
    inputProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
    menuProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      menu: false,
      pickerStart: this.value.start ? this.value.start : '',
      pickerEnd: this.value.end ? this.value.end : '',
      highlightDates: [],
      highlightClasses: {},
    }
  },
  computed: {
    inputValue() {
      if (this.isValueEmpty) {
        return ''
      }
      const start = toDateSting(this.value.start)
      const end = toDateSting(this.value.end)
      return `${start}    ${this.separatorLabel}     ${end}`
    },
    placeholder() {
      return `${this.startLabel}    ${this.separatorLabel}    ${this.endLabel}`
    },
    isValueEmpty() {
      return !this.value.start
    },
    bothSelected() {
      return this.pickerStart && this.pickerEnd
    },
    isPresetActive() {
      return this.presets.map(
        preset =>
          preset.range[0] === this.pickerStart &&
          preset.range[1] === this.pickerEnd,
      )
    },
    mergedActionLabels() {
      return {
        ...{
          apply: 'Применить',
          cancel: 'Отмена',
          reset: 'Обнулить',
        }, ...this.actionLabels,
      }
    },
  },
  methods: {
    applyRange() {
      this.menu = false
      this.emitRange()
    },

    closeMenu() {
      this.pickerStart = this.value.start
      this.pickerEnd = this.value.end
      this.highlight()
      this.$emit('menu-closed')
    },
    formatDate(date, fmt) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), fmt)
    },
    highlight() {
      if (!this.bothSelected) {
        return
      }
      const dates = []
      const classes = {}
      const start = parse(this.pickerStart, 'yyyy-MM-dd', new Date())
      const end = parse(this.pickerEnd, 'yyyy-MM-dd', new Date())
      const diff = Math.abs(differenceInCalendarDays(start, end))
      for (let i = 0; i <= diff; i++) {
        const date = format(addDays(start, i), 'yyyy-MM-dd')
        dates.push(date)
        const classesArr = []
        classesArr.push(`v-date-range__in-range`)
        classesArr.push(this.highlightColor)
        i === 0 && classesArr.push(`v-date-range__range-start`)
        i === diff && classesArr.push(`v-date-range__range-end`)
        classes[date] = classesArr.join(' ')
      }
      this.highlightDates = dates
      this.highlightClasses = classes
    },
    selectPreset(presetIndex) {
      this.pickerStart = this.presets[presetIndex].range[0]
      this.pickerEnd = this.presets[presetIndex].range[1]
    },
    reset() {
      this.pickerStart = ''
      this.pickerEnd = ''
      this.highlightDates = []
      this.highlightClasses = {}
      this.emitRange()
    },
    emitRange() {
      this.$emit('input', {
        start: isEmpty(this.pickerStart) ? null : this.pickerStart,
        end: isEmpty(this.pickerEnd) ? null : this.pickerEnd
      })
    },
  },
  watch: {
    menu(isOpen) {
      if (!isOpen) {
        this.closeMenu()
      } else {
        this.highlight()
      }
    },
    pickerStart: 'highlight',
    pickerEnd: 'highlight',
  },
}
</script>
<style scoped>
.v-date-range__input-field >>> input {
  text-align: center;
}

.v-date-range__content {
  display: flex;
}

.v-date-range__pickers >>> .v-date-picker-table__events {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.v-date-range__pickers >>> .v-date-picker-table table {
  width: auto;
  margin: auto;
  border-collapse: collapse;
}

.v-date-range__pickers >>> .v-date-range__in-range {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
</style>
